





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue, Watch } from "vue-property-decorator";
import { bus } from "@/eventBus";
import { getQuestionnaireData, updateQuestionnaire } from "@/api/questionnaire";
import { refreshtoken } from "@/api/patients";
import { IReponseData, HashTable } from "@/api/types";
import { AppModule } from "@/store/modules/app";
import { MessageBox, Message } from "element-ui";
import { UserModule } from "@/store/modules/user";
import Route from "vue-router";

@Component({
  name: "AutoEvaluationLiberaux",
})
export default class Questionnaire extends Vue {
  //#region Data
  // Liste des réponses DOS_Data
  private Responses: HashTable<IReponseData> = {};
  // Id du timer pour l'interval de rafraichissement
  private intervalRefresh = -1;
  // Booléen indiquant si l'appel à la base pour récupérer les datas a réussi
  private dataGetSuccess = false;
  // Désactive le formulaire pour une lecture readonly, non utilisé pour l'instant
  private formReadOnly = false;
  // Marqueur de modification sur le formulaire
  private formDirty = false;
  //#endregion

  //#region Computed
  get loading() {
    return AppModule.loading;
  }
  get isIdle() {
    return this.$store.state.idleVue.isIdle;
  }
  //#endregion

  //#region Watchers
  // Au changement sur le formEnable, on masque / affiche le footer
  @Watch("dataGetSuccess")
  private ondataGetSuccessChanged() {
    bus.$emit("visibility-footer", this.dataGetSuccess);
    this.formDirty = false;
  }
  //#endregion

  //#region Hooks
  // En cas d'inactivité
  onIdle() {
    ////console.log("idle");
    UserModule.LogOut(false);
    MessageBox.alert(
      "Vous avez été deconnecté pour cause d'inactivité.",
      "Déconnecté",
      {
        confirmButtonText: "Se reconnecter",
        type: "warning",
      }
    ).finally(() => {
      this.$router.push({
        path: "/login",
      });
    });
  }

  // En cas d'activité
  onActive() {
    ////console.log("active");
  }

  // Après création de la page
  created() {
    // Brancher l'évenement avant l'unload de la page
    window.addEventListener("beforeunload", this.beforeWindowUnload);
    // Brancher l'évenement submit du bus
    bus.$on("submit-form", this.submitForm);
    // Initialisation de la fonction de renouvellement de token s'il y a de l'activité
    // Toutes les 14 minutes
    this.intervalRefresh = setInterval(this.refreshToken, 1000 * 60 * 14);
    // Récupération des données
    this.getResponses();
  }

  // Après le rendu de la page
  mounted() {
    //console.log("mounted");
  }

  beforeUpdate() {
    //console.log("beforeUpdate");
  }

  updated() {
    //console.log("updated");
  }

  beforeDestroy() {
    // Désactiver l'interval de rafraichissement
    clearInterval(this.intervalRefresh);
    // Débrancher l'évenement submit du bus
    bus.$off("submit-form");
    // Débrancher l'évenement avant l'unload de la page
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  }

  beforeRouteLeave(to: Route, from: Route, next: Function) {
    // Avant de changer de route attend la confirmation de l'utilisateur si la form est dirty
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  }
  //#endregion

  //#region Methods
  // Binding de la valeur de réponse
  private getItem(fieldKey: string, fieldType: number) {
    if (this.Responses[fieldKey] == null) {
      this.Responses[fieldKey] = {
        fieldKey: fieldKey,
        fieldType: fieldType,
        fieldValue: fieldType == 11 ? "0" : "",
      };
    }
    return this.Responses[fieldKey];
  }

  // Récupération du questionnaire
  private async getResponses() {
    AppModule.ToggleLoading(true);
    try {
      const { data } = await getQuestionnaireData(this.$route.params.id, null);
      if (data.items.length > 0) {
        this.Responses = {};
        data.items.forEach((element: IReponseData) => {
          this.Responses[element.fieldKey] = element;
        });
      }
      // On attend la fin du rendu
      this.$nextTick(function () {
        this.dataGetSuccess = true;
      });
    } catch (error) {
      //console.log(error);
      this.dataGetSuccess = false;
      Message({
        message:
          "Erreur lors de la récupération des données. Retour à l'accueil.",
        type: "error",
        duration: 5 * 1000,
        onClose: this.onRedirectAccueil,
      });
    } finally {
      AppModule.ToggleLoading(false);
    }
  }

  // Envoi du formulaire
  private async submitForm() {
    //console.log(Object.values(this.Responses));
    bus.$emit("loading-submit");
    try {
      const { data } = await updateQuestionnaire(
        this.$route.params.id,
        Object.values(this.Responses)
      );
      this.formDirty = false;
      Message({
        message: "Enregistrement réussi, vous pouvez vous déconnecter",
        type: "success",
        duration: 5 * 1000,
        // Si le questionnaire est complet on retourne à l'accueil
        onClose: data.item ? this.onRedirectAccueil : undefined,
      });
    } catch (error) {
      Message({
        message: "Erreur à l'enregistrement",
        type: "error",
        duration: 5 * 1000,
      });
    } finally {
      bus.$emit("loading-submit");
    }
  }

  // Rafraichissement du token toutes les x minutes (tant qu'il y a de l'activité)
  private async refreshToken() {
    if (!this.isIdle && UserModule.connected) {
      ////console.log("Refresh");
      await refreshtoken();
    }
  }

  // Redirection à l'accueil si le questionnaire est complet
  private onRedirectAccueil() {
    this.$router.push(`/accueil`);
  }

  // Confirmation pour quitter la page
  private confirmLeave() {
    return window.confirm(
      "Cette page vous demande de confirmer sa fermeture ; des données que vous avez saisies pourraient ne pas être enregistrées ?"
    );
  }

  // Confirmation pour quitter la page
  private confirmStayInDirtyForm() {
    return this.formDirty && !this.confirmLeave();
  }

  // Evenement avant le changement de page
  private beforeWindowUnload(e: any) {
    if (this.confirmStayInDirtyForm()) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = "";
    }
  }

  // Mise à jour d'une réponse
  private onChanged(event: any) {
    this.getItem(
      event.target.name,
      parseInt(event.target.dataset.fieldtype)
    ).fieldValue = event.target.value;
    this.formDirty = true;
    this.$forceUpdate();
  }

  // private scrollToElement(name: string){
  //   console.log(this.$el.namedItem(name));
  //   console.log("dans le scroll");
  //   console.log(name);
  //   const el = document.getElementsByName(name)[0];
  //   console.log(el);
  //   console.log(this.$el);
  //   const el = this.$el.getElementsByTagName('input').namedItem(name);
  //   console.log(this.$el.getElementsByTagName('input'));
  //   console.log(name);
  //   console.log(el);
  //   console.log(this.$refs.name);
  //   console.log(this.$refs.ch2_s1_q7);
  //   A tester
  //   document.getElementsByClassName("custom-radio")
  //   if (el) {
  //     console.log("dans le scroll if");
  //     el.scrollIntoView({behavior: 'smooth'});
  //   }
  // }
  //#endregion
}
